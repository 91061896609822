import React from 'react';
import {
  FaLinkedin,
  FaGithub,
  FaAngleRight,
  FaAngleLeft,
} from 'react-icons/fa';
import {} from 'react-icons/ai';
import './team.css';

import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import Bg from '../components/parallax';

/* team 2024-25 */
import Abhishek from './assets/images/Abhishek.jpg'
import Abhinav from './assets/images/Abhinav.jpg'
import Avdhut from './assets/images/Avdhut.jpeg'
import OmPatil from './assets/images/Om.jpg'
import Pratham from './assets/images/Pratham.jpg'
import Sayali from './assets/images/SayaliKhedkar.jpeg'
import Anshul from './assets/images/AnshulS.jpg'
import Tanmay from './assets/images/TanmayBansode.png'

const addEvent = () => {
  const navbutton = document.querySelector('.menu-icon');
  navbutton.addEventListener('click', () => {
    displayNavbar();
  });
};
const displayNavbar = () => {
  const nav = document.querySelector('.nav-menu');
  const navbar = document.querySelector('.NavbarItems');
  if (nav.classList.contains('active')) {
    navbar.style.height = '91vh';
  } else {
    navbar.style.height = '80px';
  }
};

const PostsData = [
  {
    title: 'Abhishek Shinde',
    text: 'Secretary',
    image: Abhishek,
    linkedin: 'https://www.linkedin.com/in/abhi-shinde/',
    github: 'https://github.com/Mr-Abhishek-Shinde',
  },

  {
    title: 'Abhinav Kurule',
    text: 'Project Manager',
    image: Abhinav,
    linkedin: 'https://www.linkedin.com/in/abhinav-kurule-5339b3252/',
    github: 'https://github.com/abhi-1003',
  },

  {
    title: 'Avdhut Kamble',
    text: 'Project Manager',
    image: Avdhut,
    linkedin: 'https://www.linkedin.com/in/avdhut-kamble/',
    github: 'https://github.com/justafolk/',
  },
  {
    title: 'Om Patil',
    text: 'Events and Documentation Head',
    image: OmPatil,
    linkedin: 'https://www.linkedin.com/in/ompatil130503/',
    github: 'https://github.com/OmPat1l',
  },
  {
    title: 'Pratham Tandale',
    text: 'Events and Documentation Head',
    image: Pratham,
    linkedin: 'https://www.linkedin.com/in/pratham-tandale-681785232/',
    github: 'https://github.com/prathampt',
  },

  {
    title: 'Sayali Khedkar',
    text: 'Events & Documentation Head',
    image: Sayali,
    linkedin: 'https://www.linkedin.com/in/sayali-khedkar-a26414279/',
    github: 'https://github.com/khedkarsayali',
  },
  {
    title: 'Anshul Shelokar',
    text: 'Technical Head',
    image: Anshul,
    linkedin: 'https://www.linkedin.com/in/anshul-shelokar-b902a7250/',
    github: 'https://github.com/anshulshelokar71',
  },
  {
    title: 'Tanmay Bansode',
    text: 'Technical Head',
    image: Tanmay,
    linkedin: 'https://www.linkedin.com/in/tanmay-bansode-81b944258/',
    github: 'https://github.com/TanmayBansode'
  }
];

class Main extends React.Component {
  constructor() {
    super();

    this.state = {
      posts: {},
    };
  }
  componentDidMount() {
    this.setState({
      posts: PostsData,
    });
  }

  render() {
    return React.createElement(
      'div',
      { className: 'main' },
      React.createElement(Bg, null),
      React.createElement(Navbar, null),
      React.createElement(
        'div',
        { className: 'main-content' },
        React.createElement(
          'div',
          { className: 'team__content' },
          React.createElement(Title, null),
          React.createElement(Left, null),
          React.createElement(
            'div',
            { className: 'app-card-list', id: 'app-card-list' },
            Object.keys(this.state.posts).map(key =>
              React.createElement(Card, {
                key: key,
                index: key,
                details: this.state.posts[key],
              }),
            ),
          ),
          React.createElement(Right, null),
        ),
        React.createElement(Footer, null),
      ),
    );
  }
}

class Right extends React.Component {
  render() {
    return (
      <div className="team__right">
        <button
          onClick={() =>
            document.getElementById('app-card-list').scrollBy({
              top: 0,
              left: 300,
              behavior: 'smooth',
            })
          }
        >
          <i>
            <FaAngleRight color="black" size={25} />
          </i>
        </button>
      </div>
    );
  }
}

class Left extends React.Component {
  render() {
    return (
      <div className="team__left">
        <button
          className="smoothscroll"
          onClick={() =>
            document.getElementById('app-card-list').scrollBy({
              top: 0,
              left: -300,
              behavior: 'smooth',
            })
          }
        >
          <i>
            <FaAngleLeft color="black" size={25} />
          </i>
        </button>
      </div>
    );
  }
}

class Title extends React.Component {
  render() {
    return React.createElement(
      'section',
      { className: 'app-title' },
      React.createElement(
        'div',
        { className: 'app-title-content' },
        React.createElement('h1', null, 'Core Team'),
      ),
    );
  }
}

class Button extends React.Component {
  render() {
    const { linkedin, github } = this.props;
    return React.createElement(
      'button',
      { className: 'button button-primary' },
      React.createElement(
        'div',
        { className: 'links' },
        React.createElement('i', null, <FaAngleRight color="grey" size={23} />),
        React.createElement(
          'a',
          { href: linkedin, target: '_blank' },
          <FaLinkedin color="#fff" size={23} />,
        ),
        React.createElement(
          'a',
          { href: github, target: '_blank' },
          <FaGithub color="#fff" size={23} />,
        ),
      ),
    );
  }
}

class CardHeader extends React.Component {
  render() {
    const { image, category } = this.props;
    var style = {
      backgroundImage: 'url(' + image + ')',
    };

    return React.createElement(
      'header',
      { style: style, className: 'card-header' },
      React.createElement('h3', { className: 'card-header--title' }, category),
    );
  }
}

class CardBody extends React.Component {
  render() {
    return React.createElement(
      'div',
      { className: 'card-body' },
      React.createElement('h2', null, this.props.title),

      React.createElement('p', { className: 'body-content' }, this.props.text),

      React.createElement(Button, {
        linkedin: this.props.linkedin,
        github: this.props.github,
      }),
    );
  }
}

class Card extends React.Component {
  render() {
    return React.createElement(
      'article',
      { className: 'card' },
      React.createElement(CardHeader, {
        category: this.props.details.category,
        image: this.props.details.image,
      }),
      React.createElement(CardBody, {
        title: this.props.details.title,
        text: this.props.details.text,
        linkedin: this.props.details.linkedin,
        github: this.props.details.github,
      }),
    );
  }
}

export default Main;
